.div-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 7px 9px 7px; }

.danger {
  background-color: #c53929; }

.warning {
  background-color: #ffaa00; }

.success {
  background-color: #3b940f; }

.h6-one {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0; }

.h6-two {
  color: #fff;
  font-size: 11px;
  margin: 0 5px 0 5px; }

.h6-three {
  color: #c3d9ff;
  font-size: 11px;
  margin-bottom: 0;
  cursor: pointer;
  text-decoration: underline; }
