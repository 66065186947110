import { gql } from 'apollo-boost';

export const ALL_BANKS = gql`
  query ALL_BANKS($withAlliance: Boolean) {
    allBanks(withAlliance: $withAlliance) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const ALL_COMPANIES = gql`
  query ALL_COMPANIES($name: String, $typeEvent_CityId: ID) {
    company(name: $name, typeEvent_CityId: $typeEvent_CityId) {
      edges {
        node {
          id
          name
          city {
            id
            name
          }
          child {
            edges {
              node {
                id
                name
                city {
                  id
                  name
                }
                child {
                  edges {
                    node {
                      id
                      name
                      city {
                        id
                        name
                      }
                      child {
                        edges {
                          node {
                            id
                            name
                            city {
                              id
                              name
                            }
                            child {
                              edges {
                                node {
                                  id
                                  name
                                  city {
                                    id
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LEAD_QUERY = gql`
  query LEAD_QUERY($id: ID) {
    lead(id: $id) {
      edges {
        node {
          id
          user {
            id
            email
            fullName
            phoneNumber
            documentType
            documentNumber
          }
        }
      }
    }
  }
`;

export const LEAD_OPPORTUNITIES = gql`
  query LEAD_OPPORTUNITIES($code: String) {
    leadOpportunities(code: $code) {
      edges {
        node {
          id
          email
          fullName
          getFullName
          phoneNumber
          documentType
          documentNumber
        }
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query GET_PROJECTS($calendars_Url: String) {
    allTypeEvent(calendars_Url: $calendars_Url) {
      edges {
        node {
          id
          name
          url
        }
      }
    }
  }
`;
