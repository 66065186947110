import ReactGA from 'react-ga';

const actions = {
  track: page => {
    const host = global.location.host;
    const hostParts = host.split('.');
    const subDomain = hostParts[0];
    const isMarval = subDomain === 'marval';
    const uid = isMarval ? process.env.REACT_APP_GA_MARVAL : process.env.REACT_APP_GA;

    ReactGA.initialize(uid);

    ReactGA.set({ page });
    ReactGA.pageview(page);
  }
};

export const GAnalytics = actions;
