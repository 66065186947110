import React from 'react';
import { Result } from 'antd';
import 'antd/dist/antd.css';

export default ({ status, title, subTitle, extra, icon }) => {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={extra}
      icon={icon}
    />
  );
};
