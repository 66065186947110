import styled from 'styled-components';

export const Logo = styled.div`
  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: #4a4a4a 0 0 6px -2px;
    margin: 8px;

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  padding: 0px 0px;
  top: 0px;
  background: #0080ff;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 11px -3px #a9a9a9;

  @media (max-width: 768px) {
    h1 {
      margin: 0px 20px;
    }
  }

  @media (max-width: 480px) {
    text-align: center;
  }
`;
