import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { QueryParamProvider } from 'use-query-params';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import apolloClient from 'services/ApolloSetup';
import moment from 'moment';
import 'moment/locale/es';

import Routes from './Routes';
import './index.scss';

moment.locale('es');

const WrappedApp = () => (
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={esES}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Routes />
          </QueryParamProvider>
        </Router>
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>
);

ReactDOM.render(<WrappedApp />, document.getElementById('root'));
