import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import AppShellComponent from 'components/AppLoading';
import SimpleRoute from 'components/SimpleRoute';
import { GAnalytics } from './services/GAnalytics';

const AsyncHomePage = lazy(() => import('./pages/Home'));
const AsyncAppointmentCalendarPage = lazy(() => import('./pages/Appointment/pages/AppointmentCalendar'));
const AsyncAppointmentEventPage = lazy(() => import('./pages/Appointment/pages/AppointmentEvent'));
const AsyncAppointmentSuccessPage = lazy(() => import('./pages/Appointment/pages/AppointmentSuccess'));
const AsyncArrasPage = lazy(() => import('./pages/Arras'));
const Marval = lazy(() => import('./pages/Marval'));
const Lqn = lazy(() => import('./pages/Lqn'))

const Routes = props => {
  const { childProps, location } = props;

  useEffect(() => {
    GAnalytics.track(location.pathname);
  }, [props]);

  return (
    <Suspense fallback={<AppShellComponent />}>
      <Switch>
        <SimpleRoute exact path="/" component={AsyncHomePage} props={childProps} />
        <SimpleRoute exact path="/arras" component={AsyncArrasPage} props={childProps} />
        <SimpleRoute path="/marval" component={Marval} props={childProps} />
        <SimpleRoute path="/lqn" component={Lqn} props={childProps} />
        <SimpleRoute exact path="/:calendar" component={AsyncAppointmentCalendarPage} props={childProps} />
        <SimpleRoute exact path="/:calendar/:event" component={AsyncAppointmentEventPage} props={childProps} />
        <SimpleRoute
          exact
          path="/:calendar/:event/success"
          component={AsyncAppointmentSuccessPage}
          props={childProps}
        />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);
