export default {
  // AUTHORIZATION ERRORS
  1001: {
    title: 'Mmmmm, hubo un problema',
    message:
      'El correo que ingresaste al parecer, no existe en nuestra base de datos.',
  },
  1002: {
    title: 'Mmmmm, hubo un problema',
    message:
      'El correo que ingresaste ya esta registrado en nuestra base de datos. Prueba a ingresar o recupera tu contraseña',
  },
  1003: {
    title: 'Mmmmm, hubo un problema',
    message:
      'Esta cuenta se encuentra desactivada. Por favor comunicate con nosotros al 320 219 0456 para reactivar tu cuenta.',
  },
  1004: {
    title: 'Mmmmm, hubo un problema',
    message: 'Por favor verifica las datos ingresados',
  },
  1005: {
    title: 'Mmmmm, hubo un problema',
    message: 'Por favor verifica las datos ingresados',
  },
  // AGENDA ERRORS
  2001: {
    title: 'Mmmmm, hubo un problema',
    message: 'No se pudo crear la agenda, intenta nuevamente',
  },
  2002: {
    title: 'Mmmmm, hubo un problema',
    message: 'Esta fecha ya está reservada',
  },
};
