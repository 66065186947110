import React from 'react';
import './index.scss';

const Wrapper = ({ children, fluid, classes }) => (
  <div className={`wrapper ${fluid && 'wrapper__fluid'} ${classes}`}>
    {children}
  </div>
);

export default Wrapper;
