import React from 'react';
import LoaderItem from 'react-loaders';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import './index.scss';

const Loader = ({ type = 'line-scale-pulse-out', text, animated }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./animations/11161-house.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="loader-box">
      <div>
        {animated ? (
          <Lottie options={defaultOptions} height={150} width={150} />
        ) : (
          <LoaderItem type={type} color="red" />
        )}
        {text && <strong>{text}</strong>}
      </div>
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  animated: PropTypes.any
};

export default Loader;
