.header {
    position: relative;
    padding: 0px 0px;
    top: 0px;
    background: #0080FF;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 11px -3px #a9a9a9;
  
    @media (max-width: 768px) {
      padding: 10px 20px;
    }
  
    @media (max-width: 480px) {
      text-align: center;
    }
  
    img {
      height: 60px;
      
      @media (max-width: 768px) {
        height: 45px;
      }
  
      @media (max-width: 480px) {
        height: 40px;
      }
    }
  }