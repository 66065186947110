import React, { Fragment } from 'react';
import Result from 'components/ResultsView';
import PropTypes from 'prop-types';

const NotFound = ({ title, subTitle, extra }) => {
  return (
    <Fragment>
      <Result status="404" title={title} subTitle={subTitle} extra={extra}/>
    </Fragment>
  );
};

NotFound.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  extra: PropTypes.any
};

export default NotFound;
