import React from 'react';
import Loader from 'components/Loader';

/**
 * App Component Declaration
 **/
const AppLoading = () => {
  return <Loader animated text="Cargando Datos" />;
};

export default AppLoading;
