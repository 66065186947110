import React, { useEffect } from 'react';
import './index.css';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

const AlertComponent = ({ type, message }) => {
  const [cookies, setCookie] = useCookies(['visibleAlert']);
  /* const onClose = () => {
    setCookie('visibleAlert', false, { path: '/', maxAge: 21600 }); // Duración de la cookie (6 horas)
  }; */

  return (
    cookies.visibleAlert !== undefined && (
      <div className={`div-alert ${type}`}>
        <h6 className="h6-one">{message}</h6>
        {/* <h6 className="h6-two">{'-'}</h6>
        <h6 className="h6-three" onClick={onClose}>
          Cerrar
        </h6> */}
      </div>
    )
  );
};

AlertComponent.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
};

export default AlertComponent;
