import React from 'react';
import Wrapper from 'components/Wrapper';
import AlertComponent from 'components/AlertComponent';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { getCompanyImage } from 'services/Helper';
import { Logo, HeaderContainer } from './headerStyles';
import './index.scss';

const Header = props => {
  const imageAnimated = useSpring({
    transform: 'translateY(0px)',
    from: { transform: 'translateY(-80px)' }
  });
  const imgSrc = getCompanyImage(props.subdomain);
  return (
    <HeaderContainer>
      <AlertComponent
        type={'warning'}
        message={
          'Ya has agendado una cita reciéntemente. Por favor evita agendar múltiples citas para un mismo proyecto, ya que podrías congestionar el flujo de agendamientos diarios. Gracias!'
        }
      />
      <Wrapper classes="mx-auto">
        <div className="row">
          {imgSrc && (
            <Logo>
              <animated.div style={imageAnimated}>
                <img src={imgSrc} alt="logo-header" />
              </animated.div>
            </Logo>
          )}
          <h1 style={{ color: 'white', marginBottom: '0' }}>{props.text}</h1>
        </div>
      </Wrapper>
    </HeaderContainer>
  );
};

Header.propTypes = {
  text: PropTypes.string,
  subdomain: PropTypes.string
};

export default Header;
