import { gql } from 'apollo-boost';

export const calendarEvent = gql`
  query hostOcurrences($url: String, $occurrenceType: String, $date: Date, $eventTypeId: ID) {
    calendarTypeEvent(url: $url) {
      edges {
        node {
          id
          questions
          owner {
            firstName
            lastName
          }
          name
          allowTodaySchedule
          availabilityStart
          availabilityFinish
          isOnsite
          isOnline
          description
          meetTimeInterval
          color
          peopleHost {
            totalCount
            edges {
              node {
                firstName
                email
                occurrenceSet(
                  available: "ia"
                  occurrenceType_Iexact: $occurrenceType
                  date: $date
                  eventTypeId: $eventTypeId
                ) {
                  edges {
                    node {
                      id
                      user {
                        firstName
                        lastName
                        email
                      }
                      startTime
                      endTime
                      occurrenceType
                      available
                      date
                      event {
                        host {
                          email
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          url
        }
      }
    }
  }
`;

export const hostOcurrences = gql`
  query hostOcurrences($url: String) {
    calendarTypeEvent(url: $url) {
      edges {
        node {
          id
          owner {
            firstName
            lastName
          }
          name
          availabilityStart
          availabilityFinish
          faceToFaceAppointments
          remoteAppointments
          location
          description
          limitGuests
          minutesSpace
          color
          peopleHost {
            totalCount
            edges {
              node {
                firstName
                email
                occurrenceSet(available: "ia", occurrenceKind: "os") {
                  edges {
                    node {
                      user {
                        firstName
                        lastName
                        email
                      }
                      startTime
                      endTime
                      occurrenceKind
                      available
                      date
                      event {
                        host {
                          email
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          url
        }
      }
    }
  }
`;

export const calendar = gql`
  query calendar($url: String, $urlEvent: String) {
    calendar(url: $url) {
      edges {
        node {
          id
          name
          ownerHost {
            email
          }
          manyTypeEvents(isActive: true, url: $urlEvent) {
            pageInfo {
              startCursor
              hasNextPage
              hasPreviousPage
              endCursor
            }
            edges {
              node {
                id
                name
                description
                url
                color
                availabilityStart
                availabilityFinish
                allowTodaySchedule
                isActive
                isOnsite
                isOnline
              }
            }
          }
        }
      }
    }
  }
`;
