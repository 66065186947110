import { gql } from 'apollo-boost';

export const CREATE_FLAT_LEAD = gql`
  mutation CREATE_FLAT_LEAD($leadData: CreateFlatLeadInput!) {
    createLeadFlat(leadData: $leadData) {
      error
      errors
      lead {
        id
      }
      opportunity {
        id
      }
    }
  }
`;

export const ALL_COUNTRIES_COL = gql`
  query ALL_COUNTRIES_COL {
    allCountries2(code3_Iexact: "COL") {
      edges {
        node {
          id
          name
          code2
          code3
          phone
          citySet {
            edges {
              node {
                id
                name
                displayName
              }
            }
          }
        }
      }
    }
  }
`;
