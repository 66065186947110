import React, { Component } from 'react';
import StorageManager from 'services/Storage';
import jwt_decode from 'jwt-decode';

// GoogleAnalytics.initialize(process.env.REACT_APP_GA);

const withTracker = (WrappedComponent) => {
  return class Tracker extends Component {

    componentDidMount() {
      if (StorageManager.get(process.env.REACT_APP_TOKEN_SLOT)) {
        const userData = jwt_decode(StorageManager.get(process.env.REACT_APP_TOKEN_SLOT));

        if (userData && userData.userId) {
          // global.analytics.identify(userData.userId, {
          //   username: userData.username,
          //   name: userData.fullName,
          //   email: userData.email,
          //   phone: userData.phone
          // });
        }
      }
    }

    trackEvent = (name, trait) => {
      if (StorageManager.get(process.env.REACT_APP_TOKEN_SLOT)) {
        const userData = jwt_decode(StorageManager.get(process.env.REACT_APP_TOKEN_SLOT));
        if (userData) {
          // global.analytics.track(name, trait);
        }
      }
    };

    render() {
      return (
        <WrappedComponent {...this.props} trackEvent={this.trackEvent} />
      );
    }
  }
};

export default withTracker;