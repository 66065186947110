import React from 'react';
import { Route } from 'react-router-dom';
import withTracker from 'hocs/withTracker';

const SimpleRoute = ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={props => <C {...props} {...cProps} userData={rest.userData} />}
  />
);

export default withTracker(SimpleRoute);
