.wrapper {
    max-width: 1080px;
    width: 1080px;
  
    &__fluid {
      width: 100%;
      max-width: 100%;
    }
  
    @media screen and (max-width: 1200px) {
      max-width: 1080px;
      width: 1080px;
    }
  
    @media screen and (max-width: 960px) {
      max-width: 720px;
      width: 720px;
    }
  
    @media screen and (max-width: 720px) {
      max-width: 640px;
      width: 640px;
    }
  
    @media screen and (max-width: 480px) {
      max-width: 100%;
    }
  
    @media screen and (max-width: 420px) {
      max-width: 100%;
    }
  
    @media screen and (max-width: 320px) {
      max-width: 100%;
    }
}