import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';

import StorageManager from './Storage';

const middlewareLink = setContext(() => {
  const token = StorageManager.get(process.env.REACT_APP_TOKEN_SLOT);
  return {
    headers: {
      authorization: token ? `JWT ${token}` : null
    }
  };
});

const link = createUploadLink({
  uri: process.env.REACT_APP_ENDPOINT
});

const linkHttp = middlewareLink.concat(link);
const client = new ApolloClient({
  link: linkHttp,
  cache: new InMemoryCache()
});
export default client;
